import "./App.css";
import loadable from "@loadable/component";

const RouterView = loadable(() => import("./router"));

function App() {
  return (
    <div id="app" className="w-screen h-screen">
      <RouterView />
    </div>
  );
}

export default App;
